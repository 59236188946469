import React from "react"
import PropTypes from "prop-types"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Feature from "../components/feature"
import AboutUs from "../components/about-us"
import FAQ from "../components/faq"
import Development from "../components/development-index"
import HeaderIndex from "../components/header-index"
import FooterNav from "../components/footer-nav"

const IndexPage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <HeaderIndex />
    <SEO
      title="Home | MASS Blockchain Consensus Engine"
      keywords={[`MASS Blockchain Consensus Engine`]}
      lang={locale}
    />
    <Feature />
    <AboutUs />
    <Development />
    <FAQ />
    <FooterNav />
  </Layout>
)

IndexPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
}

export default IndexPage
