import React, { Fragment } from "react"
import { FormattedMessage } from "react-intl"
import LocalizedLink from "./localized-link"

import "./about-us.css"

const AboutUs = () => (
  <Fragment>
    <div className="container" style={{ position: 'relative' }}>
      <h1 className="about-us-title">
        <span>
          <FormattedMessage id="org.massnet.components.about_us.title" />
        </span>
      </h1>
      <div className="about-content">
        <p>
          <FormattedMessage id="org.massnet.components.about_us.first_para" />
        </p>
        <p>
          <FormattedMessage id="org.massnet.components.about_us.second_para" />
        </p>
        <p>
          <FormattedMessage id="org.massnet.components.about_us.third_para" />
        </p>
        <p>
          <FormattedMessage id="org.massnet.components.about_us.forth_para" />
        </p>
      </div>
      <div className="about-learn-more">
        <LocalizedLink to="/about">
          <span><FormattedMessage id="org.massnet.components.development.learn_more" /></span>
        </LocalizedLink>
      </div>
    </div>
  </Fragment>
)

export default AboutUs
