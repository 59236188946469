import React, { Fragment, Component } from "react"
import { FormattedMessage } from "react-intl"
import { Collapse } from "reactstrap"
import LocalizedLink from "./localized-link"
import { Context, Text } from "react-mathjax2"

import "./faq.css"

class QA extends Component {
  constructor(props) {
    super(props)
    this.toggle = this.toggle.bind(this)
    this.state = { collapse: false }
    this.handleDocumentClick = this.handleDocumentClick.bind(this)
  }

  toggle(e) {
    this.setState(state => ({ collapse: !state.collapse }))
    var that = this
    var dom = e.currentTarget
    // setTimeout( () => {
    //   if(that.state.collapse){
    //     let distance = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop
    //     let rect = dom.getBoundingClientRect();
    //     let top = distance + rect.top;
    //     document.documentElement.scrollTop = top - 60
    //     // window.pageYOffset = top - 60
    //     // document.body.scrollTop = top - 60
    //   }
    // },500)
  }

  handleDocumentClick(e) {
    const container = this._element
    if (e.target !== container && !container.contains(e.target)) {
      this.setState({ collapse: false })
    }
  }

  componentDidMount() {
    document.addEventListener("click", this.handleDocumentClick)
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleDocumentClick)
  }

  render() {
    return (
      <div className='faq-area' ref={c => (this._element = c)}>
        <a
          onClick={(e) => this.toggle(e)}
          className={this.state.collapse ? "faq-question" : "for-more"}
        >
          {this.props.question}
        </a>
        <Collapse isOpen={this.state.collapse}>{this.props.answer}</Collapse>
      </div>
    )
  }
}

const FAQ = () => (
  <Fragment>
    <div className="faq-fragment container" style={{ paddingBottom: "4rem", paddingTop: "3.8rem" }}>
      <h1 className="faq-title">
        <span>
          <FormattedMessage id="org.massnet.components.faq.title" />
          
        </span>
      </h1>
      <QA
        question={
          <span>
            <FormattedMessage id="org.massnet.components.faq.what_is_mass.question" />
          </span>
        }
        answer={
          <Fragment>
            <p>
              <FormattedMessage id="org.massnet.components.faq.what_is_mass.answer.first_para" />
            </p>
            <p>
              <FormattedMessage id="org.massnet.components.faq.what_is_mass.answer.second_para" />
            </p>
            <p>
              <FormattedMessage id="org.massnet.components.faq.what_is_mass.answer.third_para" />
            </p>
            <p>
              <FormattedMessage id="org.massnet.components.faq.what_is_mass.answer.forth_para" />
            </p>
          </Fragment>
        }
      />
      <QA
        question={
          <span>
            <FormattedMessage id="org.massnet.components.faq.who_created_mass.question" />
          </span>

        }
        answer={
          <Fragment>
            <p>
              <FormattedMessage id="org.massnet.components.faq.who_created_mass.answer.first_para" />
            </p>
            <p>
              <FormattedMessage id="org.massnet.components.faq.who_created_mass.answer.second_para" />
            </p>
            <p>
              <FormattedMessage id="org.massnet.components.faq.who_created_mass.answer.third_para" />
            </p>
          </Fragment>
        }
      />
      <QA
        question={
          <span>
            <FormattedMessage id="org.massnet.components.faq.problem_mass_solved.question" />
          </span>

        }
        answer={
          <Fragment>
            <p>
              <FormattedMessage id="org.massnet.components.faq.problem_mass_solved.answer.first_para" />
            </p>
            <p>
              <FormattedMessage id="org.massnet.components.faq.problem_mass_solved.answer.second_para" />
            </p>
            <p>
              <FormattedMessage id="org.massnet.components.faq.problem_mass_solved.answer.third_para" />
            </p>
            <p>
              <FormattedMessage id="org.massnet.components.faq.problem_mass_solved.answer.forth_para" />
            </p>
            <ul>
              <li>
                <FormattedMessage id="org.massnet.components.faq.problem_mass_solved.answer.fifth_para" />
              </li>
              <li>
                <FormattedMessage id="org.massnet.components.faq.problem_mass_solved.answer.sixth_para" />
              </li>
              <li>
                <FormattedMessage id="org.massnet.components.faq.problem_mass_solved.answer.seventh_para" />
              </li>
              <li>
                <FormattedMessage id="org.massnet.components.faq.problem_mass_solved.answer.eighth_para" />
              </li>
              <li>
                <FormattedMessage id="org.massnet.components.faq.problem_mass_solved.answer.ninth_para" />
              </li>
            </ul>
          </Fragment>
        }
      />
      <QA
        question={
          <span>
            <FormattedMessage id="org.massnet.components.faq.mass_feature.question" />
          </span>
        }
        answer={
          <Fragment>
            <p>
              <FormattedMessage id="org.massnet.components.faq.mass_feature.answer.first_para" />
            </p>
            <p>
              <FormattedMessage id="org.massnet.components.faq.mass_feature.answer.second_para" />
            </p>
            <p>
              <FormattedMessage id="org.massnet.components.faq.mass_feature.answer.third_para" />
            </p>
            <p>
              <FormattedMessage id="org.massnet.components.faq.mass_feature.answer.forth_para" />
            </p>
            <p>
              <FormattedMessage id="org.massnet.components.faq.mass_feature.answer.fifth_para" />
            </p>
            <p>
              <FormattedMessage id="org.massnet.components.faq.mass_feature.answer.sixth_para" />
            </p>
            <p>
              <FormattedMessage id="org.massnet.components.faq.mass_feature.answer.seventh_para" />
            </p>
            <p>
              <FormattedMessage id="org.massnet.components.faq.mass_feature.answer.eighth_para" />
            </p>
            <p>
              <FormattedMessage id="org.massnet.components.faq.mass_feature.answer.ninth_para" />
            </p>
          </Fragment>
        }
      />
      <QA
        question={
          <span>
            <FormattedMessage id="org.massnet.components.faq.what_is_poc.question" />
          </span>
        }
        answer={
          <Fragment>
            <p>
              <FormattedMessage id="org.massnet.components.faq.what_is_poc.answer" />
            </p>
          </Fragment>
        }
      />
      <QA
        question={
          <span>
            <FormattedMessage id="org.massnet.components.faq.mass_btcoin_difference.question" />
          </span>
        }
        answer={
          <Fragment>
            <p>
              <FormattedMessage id="org.massnet.components.faq.mass_btcoin_difference.answer">
                {text => (
                  <Context
                    input="tex"
                    options={{
                      tex2jax: {
                        inlineMath: [["$", "$"]],
                        preview: "none",
                      },
                    }}
                  >
                    <Text text={text} />
                  </Context>
                )}
              </FormattedMessage>
            </p>
          </Fragment>
        }
      />
      {/* <div id="faq-learn-more">
        <LocalizedLink to="/about">
          <span>
            <FormattedMessage id="org.massnet.components.development.learn_more" />
          </span>
        </LocalizedLink>
      </div> */}
    </div>
  </Fragment>
)

export default FAQ
