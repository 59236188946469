import React, { Fragment, Component } from "react"
import { FormattedMessage } from "react-intl"
import { Collapse } from "reactstrap"
import "./development-details.css"

class QA extends Component {
  constructor(props) {
    super(props)
    this.toggle = this.toggle.bind(this)
    this.state = { collapse: props.collapse || false }
    this.handleDocumentClick = this.handleDocumentClick.bind(this)

  }

  toggle() {
    this.setState(state => ({ collapse: !state.collapse }))
  }

  handleDocumentClick(e) {
    const container = this._element
    if (e.target !== container && !container.contains(e.target)) {
      this.setState({ collapse: false })
    }
  }

  componentDidMount() {
    document.addEventListener("click", this.handleDocumentClick)
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleDocumentClick)
  }

  render() {
    return (
      <div className="develop-area" ref={c => (this._element = c )}>
        <a
          onClick={this.toggle}
          className={ "develop-item " + (this.state.collapse ? "develop-item-open" : "develop-item-close")}
        >
          {this.props.question}
        </a>
        <Collapse className="collapse-item" isOpen={this.state.collapse}>{this.props.answer}</Collapse>
      </div>
    )
  }
}
const DevelopmentDetails = () => (
  <Fragment>
    <div className="index-develop-bg">
      <div className="container develop-index" id="develop-index">
        <h1 className="development-details-title">
          <span>
            <FormattedMessage id="org.massnet.components.development.title" />
          </span>
        </h1>
        <QA
          question={
            <span>
              <FormattedMessage id="org.massnet.components.development.stage.first_stage" />
            </span>
          }
          collapse = {true}
          answer={
            <Fragment>
              <ul>
                <li className="development-param">
                  <span className="development-details-content">
                    <FormattedMessage id="org.massnet.components.development.first_process" />
                  </span>
                  <span className="development-details-time">
                    <FormattedMessage id="org.massnet.components.development.first_time" />
                  </span>
                </li>
                <li className="development-param">
                  <span className="development-details-content">
                    <FormattedMessage id="org.massnet.components.development.second_process" />
                  </span>
                  <span className="development-details-time">
                    <FormattedMessage id="org.massnet.components.development.second_time" />
                  </span>
                </li>
              </ul>
              
            </Fragment>
          }
        />
         <QA
          question={
            <span>
              <FormattedMessage id="org.massnet.components.development.stage.second_stage" />
            </span>
          }
          answer={
            <Fragment>
              <ul>
                <li className="development-param">
                  <span className="development-details-content">
                    <FormattedMessage id="org.massnet.components.development.third_process" />
                  </span>
                  <span className="development-details-time">
                    <FormattedMessage id="org.massnet.components.development.third_time" />
                  </span>
                </li>
                <li className="development-param">
                  <span className="development-details-content">
                    <FormattedMessage id="org.massnet.components.development.forth_process" />
                  </span>
                  <span className="development-details-time">
                    <FormattedMessage id="org.massnet.components.development.forth_time" />
                  </span>
                </li>
                <li className="development-param">
                  <span className="development-details-content">
                    <FormattedMessage id="org.massnet.components.development.fifth_process" />
                  </span>
                  <span className="development-details-time">
                    <FormattedMessage id="org.massnet.components.development.fifth_time" />
                  </span>
                </li>
                <li className="development-param">
                  <span className="development-details-content">
                    <FormattedMessage id="org.massnet.components.development.sixth_process" />
                  </span>
                  <span className="development-details-time">
                    <FormattedMessage id="org.massnet.components.development.sixth_time" />
                  </span>
                </li>
                <li className="development-param">
                  <span className="development-details-content">
                    <FormattedMessage id="org.massnet.components.development.seventh_process" />
                  </span>
                  <span className="development-details-time">
                    <FormattedMessage id="org.massnet.components.development.seventh_time" />
                  </span>
                </li>
              </ul>
            </Fragment>
          }
        />
        <QA
          question={
            <span>
              <FormattedMessage id="org.massnet.components.development.stage.third_stage" />
            </span>
          }
          answer={
            <Fragment>
              <ul>
                <li className="development-param">
                  <span className="development-details-content">
                    <FormattedMessage id="org.massnet.components.development.eighth_process" />
                  </span>
                  <span className="development-details-time">
                    <FormattedMessage id="org.massnet.components.development.eighth_time" />
                  </span>
                </li>
              </ul>

            </Fragment>
          }
        />
        <QA
          question={
            <span>
              <FormattedMessage id="org.massnet.components.development.stage.forth_stage" />
            </span>
          }
          answer={
            <Fragment>
              <ul>
                <li className="development-param">
                  <span className="development-details-content">
                    <FormattedMessage id="org.massnet.components.development.ninth_process" />
                  </span>
                  <span className="development-details-time">
                    <FormattedMessage id="org.massnet.components.development.ninth_time" />
                  </span>
                </li>
              </ul>
            </Fragment>
          }
        />
        <QA
          question={
            <span>
              <FormattedMessage id="org.massnet.components.development.stage.fifth_stage" />
            </span>
          }
          answer={
            <Fragment>
              <ul>
                <li className="development-param">
                  <span className="development-details-content">
                    <FormattedMessage id="org.massnet.components.development.tenth_process" />
                  </span>
                  <span className="development-details-time">
                    <FormattedMessage id="org.massnet.components.development.tenth_time" />
                  </span>
                </li>
              </ul>
            </Fragment>
          }
        />
        <QA
          question={
            <span>
              <FormattedMessage id="org.massnet.components.development.stage.sixth_stage" />
            </span>
          }
          answer={
            <Fragment>
              <ul>
                <li className="development-param">
                  <span className="development-details-content">
                    <FormattedMessage id="org.massnet.components.development.eleventh_process" />
                  </span>
                  <span className="development-details-time">
                    <FormattedMessage id="org.massnet.components.development.eleventh_time" />
                  </span>
                </li>
              </ul>
            </Fragment>
          }
        />
      </div>
    </div>
  </Fragment>
)

export default DevelopmentDetails
